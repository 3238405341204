@import "../theme.module.scss";

.Footer{
  border-top: 1px solid white;
  margin-top: 70px;
  padding: 10px 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.Container{
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Warn{
  flex: 1;
  cursor: pointer;
  a{
    border: 1px solid transparent;
  }
  a:hover{
    border-bottom: 1px solid white;
  }
}

.Links{
  display: flex;
  flex: 2;
  justify-content: space-between;

  a{
    border: 1px solid transparent;
    cursor: pointer;
    &:hover{
      border-bottom: 1px solid white;
    }
  }
}

@include media("<=tablet") {
  .Container{
    flex-direction: column;
  }
  .Links{
    flex-direction: column;
    align-items: flex-start;
  }
}
