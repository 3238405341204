@import "../theme.module.scss";

.Contact{
  min-height: 100vh;
  padding: 80px 75px 0px 75px;
}
.Title{
  color: white;
  font-size: 65px;
  font-weight: bold;
}
.Description{
  color: white;
  font-size: 25px;
  font-family: Gotham, Arial, Helvetica, sans-serif;
  line-height: 40px;
  text-align: justify;
}
.Form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Actions_modal{
  display: flex;
  justify-content: center;

  button{
    min-width: 200px;
    display: flex;
    justify-content: center;
  }
}

.Actions{
  display: flex;
  justify-content: center;

  button{
    min-width: 200px;
    display: flex;
    justify-content: center;
  }
}

.campoObligatorio {
  margin-top: -14px;
  color: red;
  font-family: Gotham, Arial, Helvetica, sans-serif;
}

.containerMensaje{
  width: 800px;
  background: #E92985;
  height: 347px;
  border-radius: 40px;
  position: absolute;
  z-index: 1000;
  left: 25%;
  margin-top: 225px;
}

.equisMensaje{
  font-size: 35px;
  font-weight: bold;
  color: white;
  position: absolute;
  right: 40px;
  top: 10px;
}

.containertxt{
  text-align: center;
  margin-top: 86px;
  padding: 0 119px;
}
.txtMensaje{
  line-height: 33px;
  font-size: 30px;
  font-weight: bold;
  font-family: Futura, Arial, Helvetica, sans-serif;
  color: white;
}

.btnMensaje{
  position: absolute !important;
  right: 41% !important;
  border: 3px solid #FFFFFF !important;
  border-radius: 35px !important;
  opacity: 1 !important;
  background-color: #E92985 !important;
  width: 188px;
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-top: 8px;
  font-family: Futura, Arial, Helvetica, sans-serif;
}

.xImg {
  width: 23px;
  position: absolute;
  right: 35px;
  top: 19px;
  cursor: pointer;
}

@include media("<=tablet") {
  .containerMensaje{
    width: 340px;
    left: 5%;
    height: 435px;
  }
  .Actions_modal{
    margin-top: 80px;
    position: absolute;
    left: 77%;

  }
  .containertxt{
    margin-top: 50px;
    padding: 0;
  }
  .Contact{
    padding: 70px 20px 20px 20px;
  }
  .Title{
    font-size: 40px;
  }
  .Description{
    font-size: 20px;
    line-height: 20px;
    text-align: left;
  }
}
