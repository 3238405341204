@import "../theme.module.scss";

.About{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding-top: 90px;
}

.Section_Nosotros{
  flex: 1;
  padding: 0px 65px;
}
.Section{
  flex: 1;
  padding: 75px;
}
.Image{
  width: 100%;
  height: 100%;
  background-image: url("../images/about.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
.Title{
  color: white;
  font-size: 65px;
  font-weight: bold;
}
.Description{
  color: white;
  font-size: 22px;
  font-family: Gotham, Arial, Helvetica, sans-serif;
  line-height: 40px;
  text-align: justify;
  font-weight: bold;
}

.Descriptiontxt1{
  color: white;
  font-size: 22px;
  font-family: Gotham, Arial, Helvetica, sans-serif;
  line-height: 30px;
  text-align: justify;
}


@include media("<=tablet") {
  .About{
    flex-direction: column;
    padding-top: 80px;
  }
  .Title{
    font-size: 40px;
  }
  .Description{
    font-size: 20px;
    text-align: left;
  }
  .Section{
    padding: 0px;
  }
  .Section:first-of-type{
    padding: 40px;
  }
  .Image{
    border-radius: 0px;
  }
}

@include media(">tablet", "<desktop") {
  .Section:first-of-type{
    padding: 40px 20px 40px 40px;
  }
  .Section:last-of-type{
    padding: 40px 40px 40px 20px;
  }
}