@import "../theme.module.scss";

.Applications{
  width: 100%;
  height: 100vh;
  padding: 80px 75px 75px 75px;
}

.Title{
  color: white;
  font-size: 65px;
  font-weight: bold;
}

.Container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: visible;
  overflow-y: hidden;

  >div{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}


@include media("<=tablet") {
  .Applications{
    padding: 80px 0px 20px 0px;
    margin-top : 70vh;

  }
  .Title{
    font-size: 40px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .Container{
    flex-direction: row;
    padding-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;

    >div{
      flex-direction: column;
    }
  }
}