@import "../theme.module.scss";

.Card{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 334px;
  height: 250px;
  margin: 0 21px;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 20px;
  background: linear-gradient(234deg, rgba(3,7,52,1) 0%, rgba(233,24,133,1) 100%);
}

@include media("<=tablet") {
  .Card{
    height: 150px;
    max-width: 100vw;
    border-radius: 50px;
    h2{
      font-size: 23px;
    }
  }
}

@include media(">tablet", "<=970px") {
  .Card{
    max-width: 396px; 
  }
}

@include media("<1200px") {
  .Card{
    h2{
      font-size: 23px;
    }
    p{
      font-size: 17px;
    }
  }
}

@include media("<890px") {
  .Card{
    h2{
      font-size: 23px;
    }
  }
}