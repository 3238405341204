@import "include-media/dist/_include-media.scss";

$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

select{
  color: #000;
  font-size: 12px;
  background: transparent;
}

select option:nth-child(1){
  background-color: transparent;
  opacity: .40;
  cursor: pointer;
}

@font-face {
  font-family: 'Coolvetica';
  src: url(./fonts/coolvetica_rg.otf) format('truetype');
}